<template>
  <div class="container-fluid">
    <div class="row">

      <sidebar></sidebar>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10 h-100 pb-5">
        <div class="row">
          <div class="col-12 mb-5">
            <app-header></app-header>
            <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
          </div>
        </div>
        <section class="px-0 mx-0 px-xl-5 mx-xl-5 mb-5 pt-5 online-books">
          <div class="row justify-content-start">
            <div class="col-12 my-4">
              <b-pagination
                v-if="books.length > 0"
                @input="loadBooks"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
          <sk-cards v-if="loading" width-class="col-6 col-lg-3" :height="1.4" :count="4"></sk-cards>
          <div v-else class="row justify-content-start  row-cols-2 row-cols-sm-2  row-cols-lg-4">
            <!-- single book start -->
            <div v-for="book of books" class="col mb-4">
              <div class="bg-white mb-3 b-8 p-2 shadow-1">
                <div class="row no-gutters">
                  <div class="book__card-img-container">
                    <a href="">
                      <img :src="book.cover_url + '?token=' + token" class="img-fluid">
                    </a>
                  </div>
                  <div class="card-body px-3 py-3">
                    <!-- Title -->
                    <h4>
                      <a href="#" class="title-link">{{ book.name }}</a>
                    </h4>
                    <!-- Price -->
                    <div class="mt-4 pt-2">
                      <span class="book-price text-blue f-h-h">&#8382; {{ book.price }}</span>
                    </div>
                    <!-- Authors -->
                    <div class="mt-4 mb-5 text-muted">
                      <strong class="text-blue">{{ book.authors.length > 1 ? $t('buy-books.authors') : $t('buy-books.author') }}: </strong>
                      {{ book.authors.join(', ') }}
                    </div>
                    <a v-if="book.has_bought" :href="'/buy-books/' + book.bookID" class="btn btn-primary-red-7 py-3 d-block">{{ $t('buy-books.read') }}</a>
                    <a v-else :href="'/buy-books/' + book.bookID" class="btn btn-primary-red-7 py-3 d-block">{{ $t('buy-books.buy') }}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- single book end -->

          </div>
          <div class="row justify-content-start">
            <div class="col-12 my-4">
              <b-pagination
                v-if="books.length > 0"
                @input="loadBooks"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>
<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import CoolLightBox from 'vue-cool-lightbox';
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
  import BookResource from '../api/bookResource';
  import { mapGetters } from 'vuex';
  import SkCards from '../components/skeletons/sk-cards';

  const bookResource = new BookResource();

  export default {
    name: 'buy-books',
    components: { SkCards, sidebar, appHeader, pageHeader , CoolLightBox},
    computed: {
      ...mapGetters({
        token: 'auth/token',
      }),
      pageData() {
        return {
          title: this.$t('buy-books.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('buy-books.title'),
              link: '/buy-books',
              isActive: true,
            }],
        };
      },
    },
    data () {
      return {
        books: [],
        loading: true,
        page: 1,
        perPage: 20,
        total: 1,
      }
    },

    created() {
      this.loadBooks();
    },

    methods: {
      loadBooks() {
        this.loading = true;
        bookResource.books(this.page, this.perPage)
        .then(response => {
          this.books = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.loading = false;
        })
      }
    }
  }
</script>


